import { useState, useEffect } from "react";
import Home from "./pages/Home";
import Login from "./pages/Login";
import "./App.css";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  // const [csrfToken, setCSRFToken] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      let authRes = await fetch("/api/checkAuth", {
        method: "POST",
        credentials: "include",
      });

      // let data = authRes.text;

      if (authRes.status === 200) {
        console.log("Success!");
        return true;
      }

      console.log("Failed");
      return false;
    };

    checkAuth().then((authed) => {
      setLoggedIn(authed);
    });
  }, []);

  return <div className="App">{loggedIn ? <Home /> : <Login />}</div>;
}

export default App;
